<template>
    <div class="product-item" itemscope itemtype="http://schema.org/Thing">
        <div class="product__card product__Card--color d-flex flex-column mt-5">
            <div class="product__card-image product__image-card--color d-flex justify-content-center">
                <img itemprop="image" class="product__image" :src="require('@/assets/' + image)" alt="Compresor">
            </div>
            <div class="d-flex flex-column align-items-center">
                <div class="product__card-content">
                    <div class="product__card-title d-flex align-items-center justify-content-center mt-3">
                        <h5 itemprop="name" class="product__card-title--style product__card-text--color mb-0 px-3 px-lg-0">
                            {{ title }}
                        </h5>
                    </div>
                    <div itemprop="description" class="product__card-description mt-3 px-3">
                        <p class="product__card-description--style product__card-text--color">
                            {{ description }}
                        </p>
                        <ul>
                            <li :key="item.message" v-for="item in items" class="product__card-description--style product__card-text--color">
                                • {{ item.message }}
                            </li>
                        </ul>
                    </div>
                </div>
                <a class="mb-4" href="#contact-form" @click="eventButton" v-smooth-scroll="smoothScroolConfig">
                    <div class="product-card__button product-card__button--style d-flex align-items-center justify-content-center">
                        <p class="m-0 text-center">Más información</p>    
                    </div> 
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductItem',
    props: {
        image: String,
        title: String,
        description: String,
        items: Array,
        productID: String, 
    },
    data() {
        return {
            smoothScroolConfig: {   
                duration: 350, 
                offset: -85, 
                container: '', 
                updateHistory: true 
            }
        }
    }, 
    methods: {
        eventButton: function() {
            this.$store.dispatch('clickingCTA', 'Product:' + this.productID);
            this.$gtag.event('Click', {
                'event_category': 'Product',
                'event_label': this.title,
            })
        }
    }
}
</script>

<style scoped>
    /* Text */
    .product__card-title--style{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }
    .product__card-description--style{
        font-size: 16px;
        font-weight: 300;
        line-height: 132%;
        text-align: center;
    }
    .product__card-text--color{
        color: var(--ksr-iron);
    }
    /* Wrappers */
    .product__card{
        width: 290px;    
        transition: width 0.3s, height 0.3s;
    }
    .product__card-description{
        width: 290px;
        transition: width 0.3s, height 0.3s;
    }
    .product__card-content{
        min-height: 405px;
        transition: min-height 0.3s;
    }
    .product__card-image{
        width: 290px;
        height: 180px;
        transition: width 0.3s, height 0.3s;
    }
    .product__image{
        width: 271px;
        height: 155px;
        transition: width 0.3s, height 0.3s;
    }
    .product-card__button{
        width: 185px;
        height: 40px;
    }
    /* Styles */
    .product__Card--color{
        background-color: var(--ksr-smoke);
    }
    .product__image-card--color{
        background: var(--ksr-lightGray);
    }
    ul{
        list-style-type: none;
        padding: 0;
    }
    .product-card__button--style{
        color: var(--ksr-black);
        background-color: var(--ksr-yellow);
        transition: background-color 0.3s, color 0.3s;
    }
    .product-card__button--style:hover{
        color: var(--ksr-white);
        background-color: var(--ksr-gray);
        transition: background-color 0.3s, color 0.3s;
    }
    a{
        text-decoration: none;
    }
    @media (min-width: 992px) {
        /* Text */
        .product__card-description--style{
            text-align: left;
            transition: text-align 0.3s;
        }
        /* Wrappers */
        .product__card{
            width: 484px;
            height: 580px;
            transition: width 0.3s, height 0.3s;
        }
        .product__card-content{
            min-height: 300px;
            transition: min-height 0.3s;
        }
        .product__card-title{
            width: 405px;
            transition: width 0.3s, height 0.3s;
        }
        .product__card-description{
            width: 430px;
            transition: width 0.3s, height 0.3s;
        }
        .product__card-image{
            width: 485px;
            height: 230px;
            transition: width 0.3s, height 0.3s;
        }
        .product__image{
            width: 381px;
            height: 215px;
            transition: width 0.3s, height 0.3s;
        }
    }
</style>
